export const setTypeValue = value => {
  return { type: "SET_TYPE_VALUE", value: value }
}

export const setFilterValue = value => {
  return { type: "SET_FILTER_VALUE", value: value }
}

export const setCompanyFilterOptions = options => {
  return { type: "SET_COMPANY_FILTER_OPTIONS", options: options }
}

export const setPeopleFilterOptions = options => {
  return { type: "SET_PEOPLE_FILTER_OPTIONS", options: options }
}

export const toggleSorting = () => {
  return { type: "TOGGLE_SORTING" }
}
