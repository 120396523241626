import React from "react"
import { graphql } from "gatsby"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FullGrid from "../components/fullGrid"
import ContactSection from "../components/contactSection"

import styles from "../styles/pages/our-community.module.scss"

import Flag1 from "../images/family-flag-1.svg"
import Flag2 from "../images/family-flag-2.svg"
import Flag3 from "../images/family-flag-3.svg"

class CommunityPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const data = this.props.data
    const pageContext = this.props.pageContext //from gatsby-node
    const seo = data.contentfulOurFamilyPage.seo

    return (
      <Layout>
        <SEO
          title={seo.title}
          description={seo.description}
          keywords={seo.keywords}
        />
        <div style={{ position: "relative", overflow: "hidden" }}>
          <Fade left>
            <div className={styles.flag1}>
              <Flag1 />
            </div>
          </Fade>
          <Fade left>
            <div className={styles.flag2}>
              <Flag2 />
            </div>
          </Fade>
          <Fade left>
            <div className={styles.flag3}>
              <Flag3 />
            </div>
          </Fade>
          {/*<h1 className={styles.pageTitle}>
            {data.contentfulOurFamilyPage.title}
          </h1>*/}
          <FullGrid
            companiesFilters={data.contentfulOurFamilyPage.companiesFilters}
            defaultCompaniesDescription={
              data.contentfulOurFamilyPage.defaultCompaniesDescription
                ?.defaultCompaniesDescription
            }
            peopleFilters={data.contentfulOurFamilyPage.peopleFilters}
            defaultPeopleDescription={
              data.contentfulOurFamilyPage.defaultPeopleDescription
                ?.defaultPeopleDescription
            }
            companiesRecent={pageContext.companiesRecent}
            communityRecent={pageContext.communityRecent}
            companiesAlphabetical={pageContext.companiesAlphabetical}
            peopleAlphabetical={pageContext.peopleAlphabetical}
            flybridgeRecent={pageContext.flybridgeRecent}
            flybridgeAlphabetical={pageContext.flybridgeAlphabetical}
            advisorRecent={pageContext.advisorRecent}
            advisorAlphabetical={pageContext.advisorAlphabetical}
          />
          <ContactSection />
        </div>
      </Layout>
    )
  }
}

export default CommunityPage

export const query = graphql`
  query {
    contentfulOurFamilyPage {
      seo {
        title
        description
        keywords
      }
      title
      companiesFilters {
        title
        slug
        description {
          description
        }
      }
      defaultCompaniesDescription {
        defaultCompaniesDescription
      }
      peopleFilters {
        title
        slug
        description {
          description
        }
      }
      defaultPeopleDescription {
        defaultPeopleDescription
      }
    }
  }
`
