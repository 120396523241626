import React from "react"
import Select, { components } from "react-select"
import ArrowDropdown from "../images/arrow-dropdown.svg"

import styles from "../styles/components/grid-select.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

/** Styles for react-select **/
const selectStyles = {
  valueContainer: base => ({
    ...base,
    padding: "0",
    marginLeft: "16px",
  }),
  singleValue: base => ({
    ...base,
    margin: "0",
    width: "100%",
    maxWidth: "none",
  }),
  control: base => ({
    ...base,
    height: "100%",
    border: "none",
    borderRadius: "0",
    boxShadow: "none",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#151e2d",
  }),
  indicatorSeparator: base => ({ ...base, display: "none" }),
  indicatorsContainer: base => ({
    ...base,
    padding: "0",
    paddingRight: "6px",
  }),
  menu: base => ({
    ...base,
    margin: "0",
    border: "none",
    borderRadius: "0",
    boxShadow: "0 7px 24px rgba(0,0,0,0.16)",
    //maxHeight: "500px",
  }),
  menuList: base => ({ ...base, padding: "0" }),
  option: (base, state) => ({
    ...base,
    padding: "16px 18px",
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: state.isSelected
      ? "#26c66a"
      : state.isFocused
      ? "#eaf4f3"
      : "white",
  }),
  multiValue: base => ({ ...base, display: "none" }),
}

/** Updated components for react-select **/

// Custom arrow that animates based on whether the menu is open
const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div
          className={cx("arrow", { arrowDown: props.selectProps.menuIsOpen })}
        >
          <ArrowDropdown />
        </div>
      </components.DropdownIndicator>
    )
  )
}

const GridSelect = React.forwardRef((props, ref) => {
  const { className, options, value, onChange } = props
  return (
    <Select
      ref={ref}
      className={className}
      styles={selectStyles}
      options={options}
      value={value}
      onChange={onChange}
      components={{ DropdownIndicator }}
      isSearchable={false}
      openMenuOnFocus={true}
      maxMenuHeight={400}
      menuShouldScrollIntoView={false}
    />
  )
})

export default GridSelect
