import React from "react"
import { connect } from "react-redux"
import {
  setTypeValue,
  setFilterValue,
  toggleSorting,
  setCompanyFilterOptions,
  setPeopleFilterOptions,
} from "../actions"
import withLocation from "./withLocation"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import LazyLoad from "react-lazyload"
import Tile from "./tile"
import GridSelect from "./gridSelect"
import { removeRepeatPeople } from "../utils/grid-sorting"

import styles from "../styles/components/full-grid.module.scss"

import iconClockGray from "../images/icon-clock-gray.png"
import iconAlphabeticalGray from "../images/icon-alphabetical-gray.png"
import iconClockWhite from "../images/icon-clock-white.png"
import iconAlphabeticalWhite from "../images/icon-alphabetical-white.png"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const mapStateToProps = ({
  typeSelect,
  filterSelect,
  defaultCompanyFilterValue,
  defaultPeopleFilterValue,
  companyFilterOptions,
  peopleFilterOptions,
  sorting,
}) => ({
  typeSelect,
  filterSelect,
  defaultCompanyFilterValue,
  defaultPeopleFilterValue,
  companyFilterOptions,
  peopleFilterOptions,
  sorting,
})

const mapDispatchToProps = dispatch => ({
  setTypeValue: value => dispatch(setTypeValue(value)),
  setFilterValue: value => dispatch(setFilterValue(value)),
  setCompanyFilterOptions: options =>
    dispatch(setCompanyFilterOptions(options)),
  setPeopleFilterOptions: options => dispatch(setPeopleFilterOptions(options)),
  toggleSorting: () => dispatch(toggleSorting()),
})

/** Full grid with select components to filter it **/
class FullGrid extends React.Component {
  constructor(props) {
    super(props)

    this.companyFilterOptions = [
      this.props.defaultCompanyFilterValue,
      ...this.props.companiesFilters.map(filter => ({
        value: filter.slug,
        label: filter.title,
      })),
    ]
    this.peopleFilterOptions = [
      this.props.defaultPeopleFilterValue,
      ...this.props.peopleFilters.map(filter => ({
        value: filter.slug,
        label: filter.title,
      })),
    ]

    this.state = {
      processedArray: [], //array that will be shown on grid
      isMobile: true,
      optionsVisible: true,
      optionsTop: true,
    }

    this.didScroll = true
    this.prevScroll = 0

    this.typeSelect = React.createRef()
    this.filterSelect = React.createRef()
    //console.log("CONSTRUCT")
  }

  componentDidMount() {
    //console.log("MOUNT")
    this.props.setCompanyFilterOptions(this.companyFilterOptions)
    this.props.setPeopleFilterOptions(this.peopleFilterOptions)

    const { type, filter, sorting } = this.props.search
    this.setStateFromURL(type, filter, sorting)

    this.handleResize()
    window.addEventListener("resize", this.handleResize)
    this.setState({ processedArray: this.updateArray() })

    //scroll listener
    window.addEventListener("scroll", this.handleScroll)
    //this.interval = setInterval(this.checkScroll, 100)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
    window.removeEventListener("scroll", this.handleScroll)
  }

  //only update array when type, filter, or sorting changes
  componentDidUpdate(prevProps) {
    //console.log("COMPONENT DID UPDATE")
    const typeChange =
      prevProps.typeSelect.value !== this.props.typeSelect.value
    const filterChange =
      prevProps.filterSelect.value !== this.props.filterSelect.value
    const sortingChange = prevProps.sorting !== this.props.sorting

    if (typeChange || filterChange || sortingChange) {
      this.setState({ processedArray: this.updateArray() })
    }
  }

  handleScroll = () => {
    let scroll = window.scrollY
    if (scroll < 30) {
      //this.setState({ optionsTop: true })
      this.setState({ optionsVisible: true, optionsTop: true })
    } else if (this.prevScroll < scroll && this.state.optionsVisible) {
      //down
      //this.setState({ optionsTop: false })
      this.setState({ optionsVisible: false, optionsTop: false })
    } else if (this.prevScroll > scroll && !this.state.optionsVisible) {
      //up
      //this.setState({ optionsTop: false })
      this.setState({ optionsVisible: true, optionsTop: false })
    }
    this.prevScroll = scroll
  }

  // handleScroll = () => {
  //   this.didScroll = true
  // }

  //resets maxVisible and changes initialVisible on screen size change
  handleResize = () => {
    if (window.innerWidth > 768 && this.state.isMobile) {
      this.setState({ isMobile: false })
    } else if (window.innerWidth <= 768 && !this.state.isMobile) {
      this.setState({ isMobile: true })
    }
  }

  //when the Type changes
  handleTypeChange = value => {
    this.props.setTypeValue(value.value)
  }

  //when the Filter changes
  handleFilterChange = value => {
    this.props.setFilterValue(value.value)
  }

  toggleSorting = () => {
    this.props.toggleSorting()
  }

  setStateFromURL(type, filter, sorting) {
    //console.log("SET STATE FROM URL")
    const typeOptions = this.props.typeSelect.options.map(
      option => option.value
    )
    if (typeOptions.includes(type)) {
      this.props.setTypeValue(type)
    } else this.props.setTypeValue(this.props.typeSelect.value.value) //just used to call navigate

    let filterOptions = this.peopleFilterOptions.map(option => option.value)
    if (type === "all" || type === "companies") {
      filterOptions = this.companyFilterOptions.map(option => option.value)
    }
    if (filterOptions.includes(filter)) {
      this.props.setFilterValue(filter)
    }

    if (sorting && this.props.sorting !== sorting) {
      this.props.toggleSorting()
    }
  }

  /**
   * Returns true if the item matches the filter. Otherwise returns false.
   * @param {Object} item either a people Object or a company Object
   * @param {String} filter
   */
  filterCallback(item, filter) {
    if (item.filters) {
      let match = false
      for (let i = 0; i < item.filters.length; i++) {
        if (item.filters[i].slug === filter) {
          match = true
          break
        }
      }
      return match
    } else return false
  }

  /**
   * Returns an array s.t. all the items match the filter.
   * @param {Array} array array of either people Objects or company Objects
   * @param {String} filter
   */
  filterArray(array, filter) {
    let filteredArray = array
    if (filter && array) {
      filteredArray = array.filter(item => this.filterCallback(item, filter))
    }
    return filteredArray
  }

  /**
   * Returns array that is sorted and filtered properly, according to Flybridge specifications.
   * @param {Array} sortedArray an array of either people Objects or company Objects that is already sorted
   * @param {String} sorting either "recent" or "alphabetical"
   * @param {String} type either "people", "companies", "all"
   * @param {Object} peopleTracker object that tracks which people have already been seen
   *                               in the form {people.id : 1} if the person has been seen
   */
  processArray(sortedArray, sorting, type, filter, peopleTracker) {
    let finalArray = []

    if (sorting === "recent" || sorting === "alphabetical") {
      if (type === "all") {
        //show each company followed by their founders and investors
        this.filterArray(sortedArray, filter).forEach(company => {
          finalArray.push(company)
          finalArray = finalArray.concat(
            //can repeat founders!
            removeRepeatPeople(company.founders, peopleTracker, true),
            removeRepeatPeople(company.investors, peopleTracker)
          )
        })
      } else if (type === "companies") {
        //just show companies
        this.filterArray(sortedArray, filter).forEach(company => {
          finalArray.push(company)
        })
      }
    }
    if (sorting === "recent") {
      if (type === "people") {
        //show people related to companies, then add flybridge people, then add advisors

        //show founders & investors of each company
        sortedArray.forEach(company => {
          finalArray = finalArray.concat(
            removeRepeatPeople(
              this.filterArray(company.founders, filter),
              peopleTracker
            ),
            removeRepeatPeople(
              this.filterArray(company.investors, filter),
              peopleTracker
            )
          )
        })
        finalArray = finalArray.concat(
          //add flybridge people
          removeRepeatPeople(
            this.filterArray(this.props.flybridgeRecent, filter),
            peopleTracker
          ),
          //add advisors
          removeRepeatPeople(
            this.filterArray(this.props.advisorRecent, filter),
            peopleTracker
          )
        )
      }
    } else if (sorting === "alphabetical") {
      if (type === "people") {
        //show all people
        this.filterArray(sortedArray, filter).forEach(person => {
          finalArray.push(person)
        })
      }
    }
    return finalArray
  }

  /**
   * Updates this.state.processedArray based on the current state of sorting, type, and filter
   * Chooses an original sorted array, which is sent to this.processArray function
   */
  updateArray() {
    //console.log("UPDATE ARRAY")
    // chose different sorted array based on alphabetical / recent and company / people
    const type = this.props.typeSelect.value
      ? this.props.typeSelect.value.value
      : null
    let sortedArray = this.props.communityRecent.slice()
    if (this.props.sorting === "recent") {
      // everything sorted by recent is based on the most recently updated Company
      if (type === "all" || type === "people") {
        sortedArray = this.props.communityRecent.slice()
      } else {
        sortedArray = this.props.companiesRecent.slice()
      }
    } else if (this.props.sorting === "alphabetical") {
      if (type === "all" || type === "companies") {
        sortedArray = this.props.companiesAlphabetical
      } else if (type === "people") {
        sortedArray = this.props.peopleAlphabetical
      }
    }
    let peopleTracker = {}
    let processedArray = this.processArray(
      sortedArray,
      this.props.sorting,
      type,
      this.props.filterSelect.value?.value,
      peopleTracker
    )
    return processedArray
  }

  getDescriptionFromFilterList(currentFilter, filterList) {
    let description = null
    filterList.forEach(filter => {
      if (filter.slug === currentFilter) {
        if (filter.description) {
          description = filter.description.description
        }
      }
    })
    return description
  }

  getDescription(currentFilter, currentType) {
    if (currentType === "people") {
      if (currentFilter === null) {
        return this.props.defaultPeopleDescription
      } else {
        return this.getDescriptionFromFilterList(
          currentFilter,
          this.props.peopleFilters
        )
      }
    } else if (currentType === "companies" || currentType === "all") {
      if (currentFilter === null) {
        return this.props.defaultCompaniesDescription
      } else {
        return this.getDescriptionFromFilterList(
          currentFilter,
          this.props.companiesFilters
        )
      }
    }
  }

  render() {
    const type = this.props.typeSelect.value?.value
    const filter = this.props.filterSelect.value?.value
    const description = this.getDescription(filter, type)

    //close menu when options are not visible
    if (!this.state.optionsVisible) {
      this.typeSelect.current.state.menuIsOpen = false
      this.filterSelect.current.state.menuIsOpen = false
    }
    return (
      <div className={styles.container}>
        <div
          className={cx("subheader", {
            visible: this.state.optionsVisible,
            top: this.state.optionsTop,
          })}
        >
          <div className={styles.optionsContainer}>
            <div className={styles.sideContainer}>
              <GridSelect
                ref={this.typeSelect}
                className={styles.selectType}
                options={this.props.typeSelect.options}
                value={this.props.typeSelect.value}
                onChange={this.handleTypeChange}
              />
              <GridSelect
                ref={this.filterSelect}
                className={styles.selectFilter}
                options={this.props.filterSelect.options}
                value={this.props.filterSelect.value}
                onChange={this.handleFilterChange}
              />
            </div>
            <div className={styles.sideContainer}>
              <div className={styles.sortText}>Sort by:</div>
              <button
                className={cx("toggle", {
                  selected: this.props.sorting === "recent",
                })}
                disabled={this.props.sorting === "recent"}
                onClick={this.toggleSorting}
              >
                <img
                  src={
                    this.props.sorting === "recent"
                      ? iconClockWhite
                      : iconClockGray
                  }
                  alt="recent"
                />
              </button>
              <button
                className={cx("toggle", {
                  selected: this.props.sorting === "alphabetical",
                })}
                disabled={this.props.sorting === "alphabetical"}
                onClick={this.toggleSorting}
              >
                <img
                  src={
                    this.props.sorting === "alphabetical"
                      ? iconAlphabeticalWhite
                      : iconAlphabeticalGray
                  }
                  alt="alphabetical"
                />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.optionsPlaceholder} />

        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={200}
            classNames="grid"
            key={type + this.props.sorting + filter}
          >
            <div>
              <div className={styles.descriptionWrapper}>
                <div className={styles.description}>{description}</div>
              </div>
              <div className={styles.gridContainer}>
                {this.state.processedArray.map((gridItem, i) => (
                  <LazyLoad
                    height={this.state.isMobile ? 188 : 215}
                    offset={this.state.isMobile ? 188 : 215}
                    key={i}
                  >
                    <Tile {...gridItem} fullGrid />
                  </LazyLoad>
                ))}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
        <div className={styles.bottomSpacing} />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocation(FullGrid))
